import {
  AndroidConfigForm,
  CmsRefProps,
  CmsScreenMapper,
  ConfigForm,
  FieldValidationForm,
  Form,
  FpayForm,
  HeadersForm,
  IOSConfigForm,
  LanguageConfigForm,
  LineupPlayerStatusTextMapperForm,
  LocaleForm,
  TmForm,
  UserplanForm,
} from "../FeatureForm";
import {
  Box,
  BoxWrap,
  Card,
  Grid,
  SyncForm,
  Typography,
} from "app/pages/Dashboard/settings/features/cards/Card.style";
import {
  broadcasterDetailsSchema,
  Constants,
  euroleagueConfigSchema,
  FEATURES_NAME,
  languagesSchema,
} from "utils/constants";
import { Button } from "@dfep/ui-component";
import { capitalize, getYearArray } from "utils/functions";
import { cloneDeep } from "lodash";
import { FluidTabs } from "styles/global.style";
import { format } from "date-fns";
import { generateOptionsList } from "utils/functions";
import { getCMSLookup, getSegmentationSdkVersion } from "services/getObjects";
import { getIntegrationSchema } from "services/getObjects";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import { showLoader } from "store/reducers/loaderSlice";
import { Switch } from "styles/global.style";
import { SwitchBox, Title } from "../../syncData/dataSync.style";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import Tabs from "app/components/Common/Tabs/Tabs";

const FeatureCards = props => {
  const {
    label,
    uid,
    updated_at,
    upadteSetting,
    category,
    syncSchedule,
    liveGame,
    setLiveGame,
    createSchedule,
    description,
    sport_key,
    env_key,
    enabled,
  } = props;

  const [open, setOpen] = useState(false);
  const { access_type } = useSelector(state => state.application);
  const { READ_ONLY } = Constants;
  const [schema, setSchema] = useState([]);
  const [modifiedSchema, setModifiedSchema] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [integration, setIntegration] = useState({});
  const [cmsLookup, setCmsLookup] = useState(false);
  const [cmsCollections, setCmsCollections] = useState([]);
  const dispatch = useDispatch();

  const submitHandler = formData => {
    if (uid === "nba") {
      formData.config.recording = integration.config?.recording;

      let lineupMapper =
        integration.config?.lineup_player_status_text_mapper &&
        integration.config.lineup_player_status_text_mapper
          ? integration.config.lineup_player_status_text_mapper
          : null;

      formData.config.lineup_player_status_text_mapper = formData.lineup_player_status_text_mapper
        ? formData.lineup_player_status_text_mapper
        : lineupMapper
        ? lineupMapper
        : [];
    }
    if (uid === "contentstack") {
      let classMapper =
        integration.config?.mapper && integration.config.mapper
          ? integration.config.mapper
          : null;

      formData.config.mapper = formData.mapper
        ? formData.mapper
        : classMapper
        ? classMapper
        : [];
    }
    if (uid === "ticketmaster_new") {
      let planEventNames =
        integration.config?.plan_event_names &&
        integration.config.plan_event_names
          ? integration.config.plan_event_names
          : null;

      formData.config.plan_event_names = formData.plan_event_names
        ? formData.plan_event_names
        : planEventNames
        ? planEventNames
        : [];
    }

    if (uid === "freedompay") {
      let storeConfig =
        integration.config?.store_config && integration.config.store_config
          ? integration.config.store_config
          : null;

      formData.config.store_config = formData.store_config
        ? formData.store_config
        : storeConfig
        ? storeConfig
        : [];
    }

    if (uid === "ciam") {
      let fieldValidations =
        integration.config?.field_validations &&
        integration.config.field_validations
          ? integration.config.field_validations
          : null;

      formData.config.field_validations = formData.field_validations
        ? formData.field_validations
        : fieldValidations
        ? fieldValidations
        : [];
    }

    if (uid === FEATURES_NAME.SIGNALR_TRANSCRIPT) {
      let header =
        integration.config?.headers && integration.config.headers
          ? integration.config.headers
          : null;

      formData.config.headers = formData.headers
        ? formData.headers
        : header
        ? header
        : [];

      let locales =
        integration.config?.locale && integration.config.locale
          ? integration.config.locale
          : null;

      formData.config.locale = formData.locale
        ? formData.locale
        : locales
        ? locales
        : [];
    }

    if (uid === FEATURES_NAME.FORTRESS) {
      let user_plan =
        integration.config?.user_plan && integration.config.user_plan
          ? integration.config.user_plan
          : null;

      formData.config.user_plan = formData.user_plan
        ? formData.user_plan
        : user_plan
        ? user_plan
        : [];
    }

    if (uid === "geo_fencing") {
      let androidConfig =
        integration.config?.android_config && integration.config.android_config
          ? integration.config.android_config
          : null;

      formData.config.android_config = formData.android_config
        ? formData.android_config
        : androidConfig
        ? androidConfig
        : [];

      let iosConfig =
        integration.config?.ios_config && integration.config.ios_config
          ? integration.config.ios_config
          : null;

      formData.config.ios_config = formData.ios_config
        ? formData.ios_config
        : iosConfig
        ? iosConfig
        : [];
    }

    if (uid === FEATURES_NAME.SEGMENTATION) {
      let cms_ref_properties = integration.config.cms_ref_properties
        ? integration.config.cms_ref_properties
        : null;
      formData.config.cms_ref_properties = formData?.cms_ref_properties
        ? formData?.cms_ref_properties
        : cms_ref_properties
        ? cms_ref_properties
        : [];
      let contentstack_screen_mapper = integration.config
        ?.contentstack_screen_mapper
        ? integration.config.contentstack_screen_mapper
        : null;
      formData.config.contentstack_screen_mapper = formData?.contentstack_screen_mapper
        ? formData.contentstack_screen_mapper
        : contentstack_screen_mapper
        ? contentstack_screen_mapper
        : [];
    }

    const form = {};
    form[uid] = {
      config: formData.config,
      enabled: integration.enabled,
    };

    upadteSetting(form);
  };

  /*istanbul ignore next*/
  const closeModal = e => {
    setOpen(false);
    // reset();
  };

  const updateConfig = (config, isProtected) => {
    let newConfig = [];
    config.forEach(element => {
      if (!["recording", "mapper", "year"].includes(element.uid)) {
        newConfig = [
          ...newConfig,
          {
            ...element,
            uid: `config.${element.uid}`,
          },
        ];
      }
      if (["year"].includes(element.uid)) {
        newConfig = [
          ...newConfig,
          {
            ...element,
            uid: `config.${element.uid}`,
            field_metadata: {
              select: true,
              options: [{ label: "Select", value: "" }, ...getYearArray()],
            },
          },
        ];
      }
    });
    //for protected field
    if (isProtected !== []) {
      newConfig.forEach(element => {
        element = Object.assign(element, {
          field_metadata: { ...element.field_metadata, Protected: true },
        });
        if (isProtected.includes(`config.${element.uid}`.split(".")[2])) {
          element = Object.assign(element.field_metadata, {
            isProtected: true,
          });
        }
      });
    }
    return newConfig;
  };

  const openConfig = async () => {
    dispatch(showLoader({ show: true }));
    const {
      data: { integration },
    } = await dispatch(getIntegrationSchema({ sport_key, env_key, uid }));
    if (uid === "contentstack") {
      try {
        const response = await validateCMSCredential(integration);
        if (response?.data) {
          setCmsLookup(true);
          if (response.data?.collections)
            setCmsCollections(response.data?.collections);
        }
      } catch (err) {
        // console.log("Error is", err);
      }
    }

    if (uid === "broadcaster_details") {
      integration.schema.config = cloneDeep(broadcasterDetailsSchema);
    }

    if (uid === "euroleague") {
      integration.schema.config = cloneDeep(euroleagueConfigSchema);
    }

    if (uid === "app_languages") {
      integration.schema.config = cloneDeep(languagesSchema);
    }

    const newConfig = updateConfig(
      integration.schema.config || {},
      integration.config?.meta?.protected || [],
    );

    setSchema(newConfig);

    setIntegration({
      config: integration.config,
      enabled: integration.enabled ? integration.enabled : false,
    });
    setDefaultValues({
      config: integration.config,
      enabled: integration.enabled ? integration.enabled : false,
    });
    dispatch(showLoader({ show: false }));
    setOpen(true);
  };

  const validateCMSCredential = async settings => {
    const { enabled } = settings;

    /*istanbul ignore else*/
    if (enabled) {
      return await getCollections();
    } else {
      setCmsLookup(false);
      setCmsCollections([]);
      return Promise.resolve();
    }
  };

  const getCollections = async () => {
    return await dispatch(
      getCMSLookup({ sport_key, env_key, showToast: false }),
    );
  };

  const CardComp = {
    cardBorderColor: "4px solid #5B2B82",
    cardContent: () => (
      <>
        <BoxWrap mb={4} display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="caption">
              <span>Feature</span>
            </Typography>
            <Typography variant="h4" component="h4" title={label}>
              {label}
            </Typography>
          </Box>
          <Switch
            checked={!!enabled}
            color="primary"
            inputProps={{
              "aria-label": "primary checkbox",
              "data-testid": `${uid}_switch`,
            }}
          />
        </BoxWrap>

        <BoxWrap display="flex" alignItems="center" className="update-holder">
          <Box className="version-update">
            <Typography variant="caption" className="lbl">
              <span>Category: </span>
            </Typography>
            <Typography>{capitalize(category)}</Typography>
          </Box>

          <Box className="version-update">
            <Typography variant="caption" className="lbl">
              <span>Last Modified: </span>
            </Typography>
            <Typography>
              {updated_at && format(new Date(updated_at), "MMM dd yyyy")}
            </Typography>
          </Box>
        </BoxWrap>
      </>
    ),
  };

  //add such feature (uids) in following array where field modification is required
  const uidCheckList = ["segmentation"];

  //fetch sdk versions
  const getVersionData = useCallback(async () => {
    const {
      data: { sdk_versions },
    } = await dispatch(
      getSegmentationSdkVersion({
        url: `/v1/app/segmentation/sdk_version?device=ios&version=${process.env.REACT_APP_GRAPHQL_VERSION}`,
      }),
    );

    return sdk_versions;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key]);

  //add such feature (uids) in following array where schema modification is required
  //so as to implement mapper tab
  const uidCheck = {
    ticketmaster_new: ["config.plan_event_names"],
    nba: ["config.lineup_player_status_text_mapper"],
    freedompay: ["config.store_config"],
    ciam: ["config.field_validations"],
    signalr_transcript: ["config.headers", "config.locale"],
    fortress: ["config.user_plan"],
    geo_fencing: ["config.android_config", "config.ios_config"],
  };

  const getSchema = () => {
    if (Object.keys(uidCheck).includes(uid) || uidCheckList.includes(uid)) {
      return modifiedSchema;
    }
    return schema;
  };

  useEffect(() => {
    async function modifySchema() {
      if (
        schema.length > 0 &&
        modifiedSchema.length === 0 &&
        Object.keys(uidCheck).includes(uid)
      ) {
        const newSchema = [];
        for (let i = 0; i < schema.length; i++) {
          if (uidCheck[uid].includes(schema[i].uid)) continue;
          newSchema.push(schema[i]);
        }
        setModifiedSchema(newSchema);
      }
      if (
        schema.length > 0 &&
        modifiedSchema.length === 0 &&
        uidCheckList.includes(uid)
      ) {
        const sdkVersions = await getVersionData();

        const newSchema = cloneDeep(schema);
        Object.assign(newSchema[0].field_metadata, {
          select: true,
          options: [
            { label: "Select", value: "" },
            ...generateOptionsList({
              itemArray: sdkVersions,
              label: "version",
              value: "uid",
            }),
          ],
        });

        setModifiedSchema(newSchema);
      }
    }
    modifySchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, uid]);

  let tab = [];

  if (uid === "app_languages") {
    tab = [
      {
        title: "Languages Config",
        content:
          schema.length > 0 ? (
            <LanguageConfigForm
              schema={getSchema()}
              defaultValues={defaultValues}
              uid={uid}
              onSubmit={submitHandler}
              closeModal={closeModal}
              access_type={access_type}
            />
          ) : null,
      },
    ];
  } else {
    tab = [
      {
        title: "Config",
        content:
          schema.length > 0 ? (
            <ConfigForm
              schema={getSchema()}
              defaultValues={defaultValues}
              uid={uid}
              onSubmit={submitHandler}
              closeModal={closeModal}
              access_type={access_type}
            />
          ) : null,
      },
    ];
  }

  uid === "nba" &&
    enabled &&
    tab.push({
      title: "Sync",
      content: (
        <SyncForm>
          <div className="section">
            <Title variant="h6">To sync all the data from NBA</Title>
            <Button
              data-testid="sync"
              variant="contained"
              color="primary"
              onClick={() => {
                syncSchedule();
              }}
              disabled={access_type === READ_ONLY}
            >
              Sync Now
            </Button>
          </div>
          <div className="section">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SwitchBox>
                  <Title variant="h6">Turn On Live Game</Title>
                  <Switch
                    disabled={access_type === READ_ONLY}
                    checked={!!liveGame.enable}
                    className={
                      access_type === READ_ONLY && !!liveGame.enable
                        ? "read-only"
                        : ""
                    }
                    onChange={e => {
                      setLiveGame({
                        ...liveGame,
                        enable: e.target.checked,
                      });
                      createSchedule({
                        ...liveGame,
                        enable: e.target.checked,
                        schedule_type: "NBA_SET_LIVE_GAME",
                        service: "NBA_DJ_SERVICE",
                      });
                    }}
                    color="primary"
                    inputProps={{
                      "aria-label": "primary checkbox",
                      "data-testid": `LIVE_GAME_switch`,
                    }}
                  />
                </SwitchBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SwitchBox>
                  <Title variant="h6">Record Game</Title>
                  <Switch
                    checked={!!integration.config?.recording}
                    onChange={e => {
                      setIntegration({
                        ...integration,
                        config: {
                          ...integration.config,
                          recording: e.target.checked,
                        },
                      });
                      upadteSetting({
                        nba: {
                          config: {
                            ...integration.config,
                            recording: e.target.checked,
                          },
                          enabled: integration.enabled,
                        },
                      });
                    }}
                    disabled={!liveGame.enable || access_type === READ_ONLY}
                    color="primary"
                    inputProps={{
                      "aria-label": "primary checkbox",
                      "data-testid": `Record_switch`,
                    }}
                    className={
                      !!liveGame.enable &&
                      access_type === READ_ONLY &&
                      !!integration.config?.recording
                        ? "read-only"
                        : ""
                    }
                  />
                </SwitchBox>
              </Grid>
            </Grid>
          </div>
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
          </Box>
        </SyncForm>
      ),
    });

  uid === "nba" &&
    tab.push({
      title: "Lineup Player Status Text Mapper",
      content: (
        <LineupPlayerStatusTextMapperForm
          formType="lineup_player_status_text_mapper"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === "contentstack" &&
    integration?.enabled &&
    tab.push({
      title: "Class Mapper",
      content: cmsLookup ? (
        <Form
          formType="mapper"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          cmsCollections={cmsCollections}
          data={integration}
        />
      ) : (
        <NoData>Invalid Credentials For CMS</NoData>
      ),
    });

  uid === "ticketmaster_new" &&
    tab.push({
      title: "Plan Event Names",
      content: (
        <TmForm
          formType="plan_event_names"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === "freedompay" &&
    tab.push({
      title: "Store Config",
      content: (
        <FpayForm
          formType="store_config"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === "ciam" &&
    tab.push({
      title: "Field Validations",
      content: (
        <FieldValidationForm
          formType="field_validations"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === FEATURES_NAME.SIGNALR_TRANSCRIPT &&
    tab.push({
      title: "Headers",
      content: (
        <HeadersForm
          formType="headers"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === FEATURES_NAME.SIGNALR_TRANSCRIPT &&
    tab.push({
      title: "Locale",
      content: (
        <LocaleForm
          formType="locale"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === FEATURES_NAME.FORTRESS &&
    tab.push({
      title: "User Plan",
      content: (
        <UserplanForm
          formType="user_plan"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === "geo_fencing" &&
    tab.push({
      title: "Android Config",
      content: (
        <AndroidConfigForm
          formType="android_config"
          defaultValues={integration}
          uid={uid}
          onSubmit={submitHandler}
          closeModal={closeModal}
          access_type={access_type}
        />
      ),
    });

  uid === "geo_fencing" &&
    tab.push({
      title: "iOS Config",
      content: (
        <IOSConfigForm
          formType="ios_config"
          defaultValues={integration}
          uid={uid}
          onSubmit={submitHandler}
          closeModal={closeModal}
          access_type={access_type}
        />
      ),
    });

  uid === FEATURES_NAME.SEGMENTATION &&
    tab.push({
      title: "CMS Reference Properties",
      content: (
        <CmsRefProps
          formType="cms_ref_properties"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  uid === FEATURES_NAME.SEGMENTATION &&
    tab.push({
      title: "CMS Screen Mapper",
      content: (
        <CmsScreenMapper
          formType="contentstack_screen_mapper"
          closeModal={closeModal}
          submitHandler={submitHandler}
          access_type={access_type}
          dispatch={dispatch}
          data={integration}
        />
      ),
    });

  return (
    <>
      <Grid item lg={4} md={6} sm={12} xs={12} className="card">
        <div onClick={() => openConfig()}>
          <Card
            className="editDetails"
            cardContentclassName="cardHover deviceCard"
            {...CardComp}
          />
        </div>
      </Grid>

      {/* Disable modal */}

      {/* Form Modal */}

      {
        <Modal
          data-testid={`${uid}-modal`}
          open={open}
          handleClose={closeModal}
          title={label}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className="feature-wrapper"
          >
            <Grid item md={8}>
              {description}
            </Grid>
            <Grid item md={3} className="access-input">
              <Switch
                checked={!!integration.enabled}
                onChange={e => {
                  setIntegration({
                    config: integration.config,
                    enabled: e.target.checked,
                  });
                }}
                color="primary"
                inputProps={{
                  "aria-label": "primary checkbox",
                  "data-testid": `Enabled_switch`,
                }}
              />
            </Grid>
          </Grid>
          <FluidTabs>
            <Tabs tabs={tab} />
          </FluidTabs>
        </Modal>
      }
    </>
  );
};

export default FeatureCards;
